/* Removes focus from an element after mouse click but not after keyboard interaction.
 * For when you need a good/clear :focus style but it doesn't make sense for it to hang around after mouse click
 * Use with care - if you need this, it might be a sign that the UX needs rethinking.
 */
export default class AutoBlurButton extends React.Component {
  blur(event) {
    if (event.currentTarget) event.currentTarget.blur();
  }

  onMouseUp = event => {
    if (this.props.onMouseUp) this.props.onMouseUp(event);
    this.blur(event);
  };

  render() {
    const { children, ...restProps } = this.props;
    return (
      <button {...restProps} onMouseUp={this.onMouseUp}>
        {children}
      </button>
    );
  }
}
