import BodyPortal from 'components/common/BodyPortal';
import Modal from './Modal';

const BodyModal = props => (
  <BodyPortal>
    <Modal {...props} />
  </BodyPortal>
);

export default BodyModal;
