import ReactDOM from 'react-dom';

export default class BodyPortal extends React.Component {
  constructor() {
    super();
    this.body = document.querySelector('body');
  }

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.body);
  }
}
