import { useState, createContext } from 'react';

const FeatureFlagContext = createContext();

export const FeatureFlagProvider = ({ featureFlags, children }) => {
  const [adjustedFeatureFlags, setAdjustedFeatureFlags] = useState({
    ...featureFlags,
    consoleOpen: false,
  });

  const context = {
    featureFlags: adjustedFeatureFlags,
    setAdjustedFeatureFlags,
  };

  return <FeatureFlagContext.Provider value={context}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagProvider.propTypes = {
  featureFlags: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default FeatureFlagContext;
