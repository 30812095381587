import ReactDOM from 'react-dom';

class IdPortal extends React.Component {
  constructor(props) {
    super(props);
    this.bodyElement = document.getElementById(props.id);
  }

  render() {
    const { children } = this.props;

    if (!this.bodyElement || this.bodyElement.nodeType !== 1) return null;

    return ReactDOM.createPortal(children, this.bodyElement);
  }
}

IdPortal.props = {
  id: PropTypes.string.isRequired,
};

export default IdPortal;
