import { useCallback } from 'react';
import noop from 'lodash/noop';

import * as ErrorLogging from 'services/ErrorLogging';
import * as Analytics from 'services/analytics';

/**
 * For wrapping together a callback function (usually a change or click handler)
 * and analytics connected to those events
 * Example:
  const MealComponent = () => {
    const [servings, setServings] = useState(2);
    const onChange = useAnalyticsCallback(
      updatedServings => setServings(updatedServings),
      ({ logEvent, events, callbackArgs }) =>
        logEvent(events.changedServings, { servings: callbackArgs[0]) },
      []
    )
    return <Servings onChange={onChange} servings={servings} />;
  }
 * @param {function|null} callbackFn - optional, falls back to noop function if null
 * @param {function} analyticsCallback
 * @param {array|undefined} dependencies - React hook dependencies
 *  If no dependencies are provided, falls back to [callbackFn, analyticsCallback]
 *  to return a new function if either of those functions change
 * @return {function} - memoized based on dependencies like any useCallback React hook
 */
const useAnalyticsCallback = (callbackFn, analyticsCallback, dependencies) => {
  callbackFn = callbackFn || noop;
  dependencies = dependencies || [callbackFn, analyticsCallback];

  return useCallback((...callbackFnArgs) => {
    const callbackFnResult = callbackFn(...callbackFnArgs);

    // Analytics errors should not break the app, so rescue all errors
    try {
      analyticsCallback({
        callbackArgs: callbackFnArgs,
        logEvent: Analytics.logEvent,
        events: Analytics.events,
      });
    } catch (e) {
      ErrorLogging.captureException(e);
    }
    return callbackFnResult;
  }, dependencies);
};

export default useAnalyticsCallback;
