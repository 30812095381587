import classNames from 'classnames';

import AutoBlurButton from 'components/common/AutoBlurButton';
import CaretLeft from 'components/icons/navigation/CaretLeft';
import CaretRight from 'components/icons/navigation/CaretRight';
import style from 'styles/components/messageCenter/PromotionArrows';

const PromotionArrows = ({ scrollLeft, scrollRight, showNext, showPrev }) => (
  <React.Fragment>
    {showPrev && (
      <AutoBlurButton
        className={classNames(style.promoNavButtons, style.previous)}
        onClick={scrollLeft}
      >
        <span className="sr">Previous</span>
        <CaretLeft className="h-6 min-h-6 w-6 min-w-6" />
      </AutoBlurButton>
    )}
    {showNext && (
      <AutoBlurButton
        className={classNames(style.promoNavButtons, style.next)}
        onClick={scrollRight}
      >
        <span className="sr">Next</span>
        <CaretRight className="h-6 min-h-6 w-6 min-w-6" />
      </AutoBlurButton>
    )}
  </React.Fragment>
);

export default PromotionArrows;

PromotionArrows.propTypes = {
  showPrev: PropTypes.bool.isRequired,
  showNext: PropTypes.bool.isRequired,
  scrollLeft: PropTypes.func.isRequired,
  scrollRight: PropTypes.func.isRequired,
};
