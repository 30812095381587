import cn from 'classnames';

import { TEMPO } from 'context/BrandContext';
import useBrand from 'hooks/useBrand';
import style from 'styles/components/common/modals/OnboardingModal';

const ModalSlide = ({ description, dskImg, largeHeader, mblImg, title, transitionRef }) => {
  const isTempo = useBrand(TEMPO);

  return (
    <section className={style.section} ref={transitionRef}>
      <img className={cn(style.img, style.desktop)} alt={title} src={dskImg} />
      <img className={cn(style.img, style.mobile)} alt={title} src={mblImg} />
      <div className={style.border} />
      <h1
        className={cn(
          largeHeader ? 'mb-1 mt-5' : 'mb-2 mt-4',
          isTempo ? 'h4 text-tempo-blue-110' : `${largeHeader ? 'h4' : 'h5'} text-green-100`
        )}
      >
        {title}
      </h1>
      <p className={cn('text font-normal', isTempo && 'text-secondary')}>{description}</p>
    </section>
  );
};

ModalSlide.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dskImg: PropTypes.string.isRequired,
  mblImg: PropTypes.string.isRequired,
  largeHeader: PropTypes.bool,
};

export default ModalSlide;
